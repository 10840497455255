/** @jsx jsx */
import { jsx, Container, Flex } from "theme-ui"
import book from "../../images/results-book.png"

export const ResultsHeroWhite = () => {
  return (
    <div>
      <Container sx={{ maxWidth: 1290 }}>
        <Flex
          sx={{
            justifyContent: "space-evenly",
            alignItems: "center",
            // maxWidth: 1080,
            flexDirection: ["column", "column", "row"],
            py: 20,
          }}
        >
          <Flex
            className="left image"
            sx={{
              justifyContent: "center",
              // maxWidth: 230,
              // minWidth: 160,
              width: ["auto", "40%"],
              // mx: ["auto", 0],
              minHeight: 280,
              position: "relative",
              top: 15,
              mr: [0, 0, 30],
            }}
          >
            <iframe
              src="https://player.vimeo.com/video/530422005"
              width="640"
              // height="360"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Flex>

          <div className="right text" sx={{ p: "20px 0" }}>
            <div>
              Join Daniel G. Amen, MD and Tana Amen, BSN, RN in their new
              program
            </div>
            <h3
              sx={{
                textTransform: "uppercase",
                color: "green",
                fontSize: "xxl",
                mb: 0,
              }}
            >
              6 WEEKS TO OVERCOME
            </h3>
            <div
              sx={{
                color: "green",
                fontSize: "xl",
                fontWeight: "bold",
                lineHeight: 1.2,
              }}
            >
              Anxiety, Depression, Trauma & Grief
            </div>
            <div sx={{ fontSize: "22px" }}>
              To overcome your dragons you need to know more{" "}
              <div>about the health of your brain, body, and mind.</div>
            </div>
            <a
              href="https://courses.amenuniversity.com/sales-overcome-adtg"
              target="_blank"
              sx={{
                textTransform: "uppercase",
                color: "white",
                bg: "green",
                borderRadius: "50px",
                px: "20px",
                py: "5px",
                display: "inline-block",
                mt: 15,
                "&:hover": {
                  bg: "grey",
                },
              }}
            >
              Learn More
            </a>
          </div>
        </Flex>
      </Container>
    </div>
  )
}
