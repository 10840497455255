/** @jsx jsx */
import { jsx, Container } from "theme-ui"
import React from "react"
import ls from "local-storage"
import { Router } from "@reach/router"

import {
  SmallDetectedDragons,
  SubmitForm,
  FinalResult,
  Layout,
  SEO,
} from "../components"
import { ResultsHeroGreen, ResultsHeroWhite } from "../components/resultsHero"

const Results = ({ params }) => {
  const allDragons = ls("allDragons")

  const localAnswers = ls("localAnswers")

  const detectedDragonsTitles =
    localAnswers &&
    localAnswers.filter(answer => answer.detected).map(dragon => dragon.title)

  const detectedDragonsData =
    allDragons &&
    allDragons.filter(dragon => detectedDragonsTitles.includes(dragon.title))

  const ResultSubmit = ({ detectedDragonsData, localAnswers }) => {
    return (
      <Container sx={{ mt: 35 }}>
        <SmallDetectedDragons detectedDragonsData={detectedDragonsData} />
        <SubmitForm
          detectedDragonsData={detectedDragonsData}
          localAnswers={localAnswers}
          allDragons={allDragons}
        />
      </Container>
    )
  }

  return (
    <Layout>
      <SEO title="results" />
      {Object.keys(params).length > 0 && (
        <>
          <ResultsHeroGreen />
          <ResultsHeroWhite />
        </>
      )}
      <div sx={{ bg: "green", py: 25, textAlign: "center", color: "#fff" }}>
        <Container>
          <h1 sx={{ m: 0 }}>
            Know Your HIDDEN Dragons Quiz{" "}
            <span sx={{ color: "orange" }}>RESULTS</span>
          </h1>
        </Container>
      </div>
      <Router>
        <ResultSubmit
          detectedDragonsData={detectedDragonsData}
          localAnswers={localAnswers}
          path="results"
        />
        <FinalResult path="results/:id" />
      </Router>
    </Layout>
  )
}
export default Results
